angular.module('fingerink')
    .service('websocketService', function (session) {


        this.connect = (s, e) => {
            var socket = new SockJS('http://localhost:8080/websocket');
            this.ws = Stomp.over(socket);
            this.ws.connect({'mierda': 'token'}, s(),e());
        };

        this.disconnect = () => {
            if (this.ws != null) {
                this.ws.close();
            }
            setConnected(false);
            console.log("Disconnected");
        };

        this.subscribe= (c,f)=> this.ws.subscribe(c, f, {token: session.get().token});

    });
